<template>
  <nav class="userNavAreaSp sp">
    <ul class="userEditNav">
      <li class="profile">
        <template v-if="page === 'profile'">
          <span>プロフィール</span>
        </template>
        <template v-else>
          <router-link :to="{ name: 'user-edit' }">
            プロフィール
          </router-link>
        </template>
      </li>
      <li class="message">
        <template v-if="page === 'message'">
          <span>メッセージ<span v-if="getUnreadMessageCount > 0" class="unread">{{
            getUnreadMessageCount
          }}</span></span>
        </template>
        <template v-else>
          <router-link :to="{ name: 'user-inbox' }">
            メッセージ<span v-if="getUnreadMessageCount > 0" class="unread">{{
              getUnreadMessageCount
            }}</span>
          </router-link>
        </template>
      </li>
      <li class="pageview">
        <template v-if="page === 'pageview'">
          <span>ページビュー</span>
        </template>
        <template v-else>
          <router-link :to="{ name: 'user-pageview' }">
            ページビュー
          </router-link>
        </template>
      </li>
    </ul>
    <!-- /.userNavArea -->
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserNavSp',
  props: {
    page: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      getUnreadMessageCount: 'getUnreadMessageCount'
    })
  }
}
</script>
