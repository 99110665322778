<template>
  <tr>
    <td>
      <div class="wrap">
        <p class="year">
          <strong>{{ ty.year }}</strong><span>{{ jyear }}</span>
        </p>
        <p class="affair">
          {{ affair }}
        </p>
      </div>
    </td>
    <td>
      <p class="age">
        <span>{{ age }}</span>
      </p>
    </td>
    <td>
      <div class="comment">
        <p>
          <textarea
            v-model="ty.comment"
            placeholder="ここに自分の1年ごとの歴史をいれます。100〜120文字程度"
          />
        </p>
      </div>
      <div class="imageWrap">
        <p v-if="ty.image" class="image">
          <img :src="ty.image" alt="" :class="imgClass">
        </p>
        <p v-if="ty.image" class="delete" @click="removeImage">
          <a />
        </p>
        <p v-show="!ty.image" class="addImage">
          <label>写真を追加
            <input
              type="file"
              :disabled="isUploading"
              @change="onImageChange"
            >
          </label>
        </p>
      </div>
    </td>
  </tr>
</template>

<script>
import { ageAt } from '@/helpers/timetable.js'
import { mapGetters } from 'vuex'

export default {
  props: {
    propTimetableYear: {
      type: Object,
      required: true
    },
    propBirthday: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      ty: null,
      isUploading: false,
      imgClass: ['obj-fit-img'],
      jyear: null,
      affair: null,
      age: null
    }
  },
  computed: {
    ...mapGetters({
      getYearAffairByYear: 'yearAffair/getYearAffairByYear'
    }),
    formInvalid: function () {
      if (this.isUploading) {
        return true
      }
      return false
    }
  },
  created () {
    this.ty = this.propTimetableYear
    this.ty.comment = this.ty.comment.replace(/(<([^>]+)>)/gi, '')

    const yearAffair = this.getYearAffairByYear(this.ty.year)
    if (yearAffair) {
      this.jyear = yearAffair.jyear
      this.affair = yearAffair.affair
    }
    this.age = ageAt(this.ty.year, this.propBirthday)
  },
  methods: {
    onImageChange (e) {
      this.isUploading = true

      this.imgClass.push('uploading-overlay')

      const files = e.target.files || e.dataTransfer.files

      const reader = new FileReader()
      reader.onload = f => {
        this.ty.image = f.target.result
      }
      reader.readAsDataURL(files[0])

      this.$store
        .dispatch('user/uploadImage', files[0])
        .then(res => {
          this.ty.image = res.data.file.url
          this.isUploading = false

          this.imgClass = this.imgClass.filter(c => c !== 'uploading-overlay')
        })
        .catch(() => {
          this.isUploading = false
        })
    },
    removeImage () {
      this.ty.image = null
    }
  }
}
</script>

<style scoped>
p.addImage > label > input {
  display: none;
}

p.addImage > label {
  display: block;
  font-size: 18px;
  font-weight: bold;
  height: 40px;
  padding: 10px 0 0 52px;
  color: #000;
  text-decoration: none;
  transition: 0.5s;
}

p.addImage > label::before,
p.addImage label::after {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  border-top: 3px solid #fff;
  position: absolute;
  top: 23px;
  left: 26px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

p.addImage > label::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  top: 16px;
  left: 12px;
}

p.image > img.uploading-overlay {
  opacity: 0.75;
}

@media screen and (max-width: 767px) {
  p.addImage > label::before {
    width: 3.73333vw;
    height: 3.73333vw;
    top: 5.73333vw;
    left: 7.6vw;
  }

  p.addImage > label::after {
    width: 3.73333vw;
    height: 3.73333vw;
    top: 4vw;
    left: 3.73333vw;
  }
}
</style>
