<template>
  <div>
    <div class="chronologyArea">
      <h2>{{ getLoggedInUser.full_name }} 年表</h2>
      <table>
        <template v-for="ty in timetable.years">
          <TimetableYearForm
            ref="timetableYearForm"
            :key="ty.year"
            :prop-timetable-year="ty"
            :prop-birthday="birthday"
          />
        </template>
      </table>
      <!-- /.chronologyArea -->
    </div>

    <div class="editApprovalArea">
      <p>
        <label><input v-model="timetable.allow_comments" type="checkbox"><span>コメントを許可する</span></label>
      </p>
      <!-- /.editApprovalArea -->
    </div>

    <div class="buttonArea">
      <div class="inner">
        <p class="private">
          <button
            type="submit"
            :disabled="formInvalid"
            @click="saveTimetable($event)"
          >
            保存する（非公開）
          </button>
        </p>
        <p class="submit">
          <button
            type="submit"
            :disabled="formInvalid"
            @click="postTimetable($event)"
          >
            公開する
          </button>
        </p>
      </div>
      <!-- /.buttonArea -->
    </div>

    <!-- モーダル -->
    <modal name="form-submitted">
      <div
        id="modalCompleteSave"
        class="modal"
        style="display: block !important;"
      >
        <div class="modalOverlay" />
        <div class="modalInner">
          <dl>
            <dt>{{ modalTitle }}</dt>
            <dd>{{ modalText }}</dd>
          </dl>
          <div class="buttonArea">
            <p class="back">
              <router-link
                :to="{
                  name: 'user-timetable',
                  params: { id: getLoggedInUser.id }
                }"
              >
                コンテンツ一覧へ戻る
              </router-link>
            </p>
          </div>
          <p class="close">
            <a class="closeModal" @click="closeModal" />
          </p>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { authComputed } from '@/store/helpers.js'
import NProgress from 'nprogress'
import TimetableYearForm from '@/components/TimetableYearForm.vue'

export default {
  components: {
    TimetableYearForm
  },
  props: {
    propTimetable: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      timetable: null,
      isUploading: false,
      birthday: null,
      modalTitle: '',
      modalText: ''
    }
  },
  computed: {
    ...authComputed,
    formInvalid: function () {
      if (this.isUploading) {
        return true
      }
      return false
    }
  },
  created () {
    this.timetable = this.propTimetable
    this.birthday = this.$store.state.loggedInUser.birthday
    this.$store.dispatch('yearAffair/fetchYearAffairs')
  },
  methods: {
    closeModal () {
      this.$modal.hide('form-submitted')
    },
    saveTimetable (event) {
      event.preventDefault()
      this.timetable.display = false
      this.modalTitle = '保存完了'
      this.modalText = 'コンテンツを保存しました。'
      this.updateTimetable()
    },
    postTimetable (event) {
      event.preventDefault()
      this.timetable.display = true
      this.modalTitle = '公開完了'
      this.modalText = 'コンテンツを公開しました。'
      this.updateTimetable()
    },
    updateTimetable () {
      this.isUploading = true
      NProgress.start()

      this.$store
        .dispatch('timetable/updateTimetable', this.timetable)
        .then(() => {
          this.isUploading = false
          this.$modal.show('form-submitted')
          NProgress.done()
        })
        .catch(() => {
          this.isUploading = false
          NProgress.done()
          this.$router.push({ name: 'logout' })
        })
    }
  }
}
</script>

<style>
.v--modal {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
</style>
