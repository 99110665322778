<template>
  <div>
    <div id="pageEditChronology" class="mainContents">
      <UserNavSp :page="'timetable'" />

      <UserTitle :user="user" />

      <UserNav :user="user" :page="'timetable'" />

      <TimetableForm ref="timetableForm" :prop-timetable="timetable" />

      <!-- /.mainContents -->
    </div>
  </div>
</template>

<script>
import TimetableForm from '@/components/TimetableForm.vue'
import UserTitle from '@/components/UserTitle.vue'
import UserNav from '@/components/UserNav.vue'
import UserNavSp from '@/components/UserNavSp.vue'

export default {
  components: {
    TimetableForm,
    UserTitle,
    UserNav,
    UserNavSp
  },
  props: {
    timetable: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      user: {}
    }
  },
  beforeDestroy () {},
  created: function () {
    this.user = this.$store.getters.getLoggedInUser
  }
}
</script>

<style scoped></style>
