<template>
  <div class="userTitleArea" :class="user.cover_color">
    <div class="profile" :class="user.profile_color">
      <p class="image">
        <BaseUserImg :user="user" :size="200" />
      </p>
      <dl>
        <dt>{{ user.full_name }}</dt>
        <dd>{{ user.prefecture }}</dd>
      </dl>
    </div>
    <p class="cover">
      <img
        v-if="user.cover_image"
        :src="user.cover_image"
        alt=""
        class="obj-fit-img"
      >
    </p>
    <div class="overlay" />
    <!-- /.userTitleArea -->
  </div>
</template>

<script>
export default {
  name: 'UserTitle',
  props: {
    user: {
      type: Object,
      default: null
    }
  },
  methods: {}
}
</script>
