<template>
  <nav class="userNavArea">
    <ul class="userNav">
      <li>
        <template v-if="page === 'episode'">
          <span>エピソード</span>
        </template>
        <template v-else>
          <router-link :to="{ name: 'user-show', params: { id: user.id } }">
            エピソード
          </router-link>
        </template>
      </li>
      <li>
        <template v-if="page === 'history'">
          <span>自分史</span>
        </template>
        <template v-else>
          <router-link :to="{ name: 'user-history', params: { id: user.id } }">
            自分史
          </router-link>
        </template>
      </li>
      <li>
        <template v-if="page === 'timetable'">
          <span>年表</span>
        </template>
        <template v-else>
          <router-link
            :to="{ name: 'user-timetable', params: { id: user.id } }"
          >
            年表
          </router-link>
        </template>
      </li>
    </ul>
    <ul v-if="isOwner(user.id)" class="userEditNav pc tab">
      <li class="profile">
        <template v-if="page === 'profile'">
          <span>プロフィール</span>
        </template>
        <template v-else>
          <router-link :to="{ name: 'user-edit' }">
            プロフィール
          </router-link>
        </template>
      </li>
      <li class="message">
        <template v-if="page === 'message'">
          <span>メッセージ<span v-if="getUnreadMessageCount > 0" class="unread">{{
            getUnreadMessageCount
          }}</span></span>
        </template>
        <template v-else>
          <router-link :to="{ name: 'user-inbox' }">
            メッセージ<span v-if="getUnreadMessageCount > 0" class="unread">{{
              getUnreadMessageCount
            }}</span>
          </router-link>
        </template>
      </li>
      <li class="pageview">
        <template v-if="page === 'pageview'">
          <span>ページビュー</span>
        </template>
        <template v-else>
          <router-link :to="{ name: 'user-pageview' }">
            ページビュー
          </router-link>
        </template>
      </li>
    </ul>
    <!-- /.userNavArea -->
  </nav>
</template>

<script>
import { authComputed } from '@/store/helpers.js'
import { mapGetters } from 'vuex'

export default {
  name: 'UserNav',
  props: {
    user: {
      type: Object,
      default: null
    },
    page: {
      type: String,
      default: 'episode'
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      getUnreadMessageCount: 'getUnreadMessageCount'
    })
  },
  created: function () {}
}
</script>
