export function ageAt (year, birthday) {
  var b = new Date(birthday)
  var when = new Date(year + '-12-31')

  var ageDifMs = when.getTime() - b.getTime()
  var ageDate = new Date(ageDifMs)

  const age = ageDate.getUTCFullYear() - 1970
  if (age > 0) {
    return age
  }

  return 0
}
